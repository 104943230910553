import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import SliderEvents from './sliderEvents/SliderEvents';
import SliderNews from './sliderNews/SliderNews';
import SliderReviews from './sliderReviews/SliderReviews';
import Questions from './questions/Questions';
import services from '../../../services/services';
import './mainHome.css';

import dataFaq from '../../../data/faq.json';

import minonuk from '../../../images/minonuk.png';
import minonuk1920 from '../../../images/minonuk_1920.png';
import imzo from '../../../images/imzo.png';
import imzo1920 from '../../../images/imzo_1920.png';
import inped from '../../../images/inped.png';
import inped1920 from '../../../images/inped_1920.png';
// import workplace from '../../../images/workplace.png'
// import workplace992 from '../../../images/workplace_992.png'
// import workplace1200 from '../../../images/workplace_1200.png'
import workplace1920 from '../../../images/workplace_1920.webp';
import worldCard from '../../../images/world_card.png';
import worldCard1200 from '../../../images/world_card_1200.png';
import worldCard1920 from '../../../images/world_card_1920.png';
// import tablet from '../../../images/tablet.png'
// import tablet1200 from '../../../images/tablet_1200.png'
import tablet1920 from '../../../images/tablet_1920.webp';
// import diary from '../../../images/diary.png'
// import diary1200 from '../../../images/diary_1200.png'
import diary1920 from '../../../images/diary_1920.webp';
import mapImg from '../../../images/map.png';
import mapImg992 from '../../../images/map_992.png';
import mapImg1920 from '../../../images/map_1920.png';
//import craftbrain from '../../../images/Craftbrain.png';
import diya from '../../../images/Diya.png';
import gigacloud from '../../../images/Gigacloud.png';
import gigatrans from '../../../images/Gigatrans.png';

//import dataNews from "../../../data/news.json";

const MainHome = () => {
  const [isError, setIsError] = useState(false);
  const [newNews, setNewNews] = useState([]);

  useEffect(() => {
    services
      .getNewsInfo()
      .then((response) => {
        setNewNews(response.data);
      })
      .catch((err) => {
        setIsError(err);
        return isError;
      });
    // eslint-disable-next-line
  }, []);

  const dataEvents = [];
  //newNews?.filter((item) => item.IsPrimary)
  //?.filter((item) => item.event);

  return (
    <div className='mainHome'>
      <div className='container'>
        <section className='recommendation'>
          <div className='recommendation-title-overlay'>
            <h2 className='recommendation-title'>
              Рекомендовано та<span></span> підтримано провідними<span></span>{' '}
              освітніми інституціями
            </h2>
            <NavLink className='mainHome__light-btn' to='/about'>
              Дізнатися більше
            </NavLink>
          </div>
          <div className='recommendation-logo-overlay'>
            <div className='recommendation-logo logo-minonuk'>
              <picture>
                <source media='(min-width: 1200px)' srcSet={minonuk1920} />
                <img src={minonuk} alt='minonuk' />
              </picture>
            </div>
            <div className='recommendation-logo logo-imzo'>
              <picture>
                <source media='(min-width: 1920px)' srcSet={imzo1920} />
                <img src={imzo} alt='imzo' />
              </picture>
            </div>
            <div className='recommendation-logo logo-inped'>
              <picture>
                <source media='(min-width: 1200px)' srcSet={inped1920} />
                <img src={inped} alt='inped' />
              </picture>
            </div>
          </div>
        </section>
        <section className='system'>
          <p className='system-description'>
            <span>Інформаційно-комунікаційна автоматизована система,</span>{' '}
            призначена для закладів освіти, учнів та їх батьків, а також для
            органів управління освіти
          </p>
          <div className='system-img'>
            <picture>
              <source media='(min-width: 1920px)' srcSet={workplace1920} />
              <source media='(min-width: 1200px)' srcSet={workplace1920} />
              <source media='(min-width: 992px)' srcSet={workplace1920} />
              <img src={workplace1920} alt='workplace' />
            </picture>
          </div>
        </section>
        <section className='purpose'>
          <div className='purpose-content-overlay'>
            <h2 className='section__title'>Мета системи Єдина Школа</h2>
            <ul className='purposeList'>
              <li className='purposeList-item'>
                <p className='purposeList-item-title'>Ефективність</p>
                <p className='purposeList-item-descr'>
                  Запровадити інноваційну систему для підвищення ефективності
                  закладу освіти.
                </p>
              </li>
              <li className='purposeList-item'>
                <p className='purposeList-item-title'>Покращення</p>
                <p className='purposeList-item-descr'>
                  Мотивувати учнів до відвідування занять та вивчення навчальних
                  предметів.
                </p>
              </li>
              <li className='purposeList-item'>
                <p className='purposeList-item-title'>Зв’язок</p>
                <p className='purposeList-item-descr'>
                  Налагодити інтерактивний зв`язок між батьками та вчителями.
                </p>
              </li>
              <li className='purposeList-item'>
                <p className='purposeList-item-title'>Доступ</p>
                <p className='purposeList-item-descr'>
                  Налаштувати батькам доступ до інформації щодо їхньої дитини.
                </p>
              </li>
            </ul>
          </div>
          <div className='purpose-img'>
            <picture>
              <source media='(min-width: 1920px)' srcSet={worldCard1920} />
              <source media='(min-width: 1200px)' srcSet={worldCard1200} />
              <img src={worldCard} alt='world_card' />
            </picture>
          </div>
        </section>
        <section className='journal'>
          <div className='journal-content-overlay'>
            <h2 className='section__title'>Електронний журнал</h2>
            <p className='journal-content-descr'>Занотовувати зміст уроку.</p>
            <p className='journal-content-descr'>
              Відмічати відвідуваність та виставляти оцінки.
            </p>
            <p className='journal-content-descr'>
              Обирати тип заняття та вид оцінки.
            </p>
            <p className='journal-content-descr'>
              Створювати домашнє завдання та прикріпляти додані файли до нього у
              вигляді текстових, фото-, відео-, аудіо- матеріалів.
            </p>
            <p className='journal-content-descr'>
              Друкувати сторінки електронного журналу та зберігати в архівному
              файлі; імпортувати/експортувати дані (MS Excel, PDF тощо).
            </p>
            <NavLink className='mainHome__light-btn' to='/teacher'>
              Дізнатися більше
            </NavLink>
          </div>
          <div className='journal-img'>
            <picture>
              <source media='(min-width: 1920px)' srcSet={tablet1920} />
              <source media='(min-width: 1200px)' srcSet={tablet1920} />
              <img src={tablet1920} alt='tablet' />
            </picture>
          </div>
        </section>
        <section className='diary'>
          <div className='diary-content-overlay'>
            <h2 className='section__title'>Електронний щоденник</h2>
            <p className='diary-content-discr'>
              Постійний доступ батьків до навчального процесу дитини, моніторинг
              відвідуваності навчальних закладів, контроль рівня знань.
            </p>
            <p className='diary-content-discr'>
              Розсилання push-повідомлень у визначених випадках, неспроможність
              втручання третіх осіб у систему.
            </p>
            <p className='diary-content-discr'>
              Інтерактивний зв`язок батьків з кожним вчителем через наявну в
              системі електронну пошту.
            </p>
            <NavLink className='mainHome__light-btn' to='/parents'>
              Дізнатися більше
            </NavLink>
          </div>
          <div className='diary-img-overlay'>
            <div className='diary-img'>
              <picture>
                <source media='(min-width: 1920px)' srcSet={diary1920} />
                <source media='(min-width: 1200px)' srcSet={diary1920} />
                <img src={diary1920} alt='diary_mobile' />
              </picture>
            </div>
          </div>
        </section>
        <section className='schoolsUse'>
          <div className='schoolsUse-content-overlay'>
            <h2 className='section__title'>
              Найсучасніші школи використовують Єдину Школу
            </h2>
            <p className='schoolsUse-content-discr'>
              Навчальні заклади вже почали використовувати систему, приєднуйтесь
              і ви, зробіть крок у новітні технології, які допоможуть полегшити
              взаємодію між учителями, батьками і учнями.
            </p>
            <div className='schoolsUse-counter'>
              <div className='schoolsUse-counter-item-overlay'>
                <div className='schoolsUse-counter-item'>
                  <p className='schoolsUse-counter-item-number'>24</p>
                  <p className='schoolsUse-counter-item-discr'>області</p>
                </div>
                <div className='schoolsUse-counter-item'>
                  <p className='schoolsUse-counter-item-number'>150+</p>
                  <p className='schoolsUse-counter-item-discr'>міст</p>
                </div>
                <div className='schoolsUse-counter-item'>
                  <p className='schoolsUse-counter-item-number'>2600+</p>
                  <p className='schoolsUse-counter-item-discr'>закладів</p>
                </div>
              </div>
              <div className='schoolsUse-counter-item-overlay'>
                <div className='schoolsUse-counter-item'>
                  <p className='schoolsUse-counter-item-number'>61000+</p>
                  <p className='schoolsUse-counter-item-discr'>вчителів</p>
                </div>
                <div className='schoolsUse-counter-item'>
                  <p className='schoolsUse-counter-item-number'>775000+</p>
                  <p className='schoolsUse-counter-item-discr'>учнів</p>
                </div>
                <div className='schoolsUse-counter-item'>
                  <p className='schoolsUse-counter-item-number'>825000+</p>
                  <p className='schoolsUse-counter-item-discr'>батьків</p>
                </div>
              </div>
            </div>
          </div>
          <div className='schoolsUse-img'>
            <picture>
              <source media='(min-width: 1200px)' srcSet={mapImg1920} />
              <source media='(min-width: 992px)' srcSet={mapImg992} />
              <img src={mapImg} alt='map' />
            </picture>
          </div>
        </section>
      </div>
      <div className='mainReigistration'>
        <NavLink className='mainReigistration__btn' to='/schoolRegistration'>
          Зареєструвати заклад
        </NavLink>
      </div>
      <div className='container'>
        {dataEvents?.length > 0 && (
          <section className='event-section'>
            <h2 className='section__title event__title'>Анонс подій</h2>
            <SliderEvents dataNews={dataEvents} />
          </section>
        )}
        <section className='news'>
          <h2 className='section__title'>Новини</h2>
          <SliderNews news={newNews} />
        </section>
        <section className='partners'>
          {/* <div className='item left'>
            <header>
              <h2 className='section__title'>
                Представники в Європейському Союзі
              </h2>
            </header>
            <article className='mainTextConteiner'>
              <picture>
                <source srcSet={craftbrain} />
                <img src={craftbrain} alt='inped' />
              </picture>
              <div className='adressComponent'>
                <p>Craftbrain Global s.r.o.</p>
                <p>Director: Iveta Vojikova</p>
                <p>Italská 2581/67, Vinohrady,</p>
                <p>120 00 Praha 2, Czech Republic</p>
              </div>
            </article>
          </div> */}
          <div className='item2 right'>
            <header>
              <h2 className='section__title'>Наші партнери</h2>
            </header>
            <article className='mainTextConteiner2'>
              <picture className='picture1'>
                <source srcSet={diya} />
                <img src={diya} alt='inped' />
              </picture>
              <picture className='picture2'>
                <source srcSet={gigacloud} />
                <img src={gigacloud} alt='inped' />
              </picture>
              <picture className='picture3'>
                <source srcSet={gigatrans} />
                <img src={gigatrans} alt='inped' />
              </picture>
            </article>
          </div>
        </section>
        <section className='faq'>
          <h2 className='section__title'>Поширені запитання (FAQ)</h2>
          <Questions data={dataFaq} />
        </section>
        <section className='reviews'>
          <h2 className='section__title'>Відгуки</h2>
          <SliderReviews />
        </section>
      </div>
    </div>
  );
};

export default MainHome;
